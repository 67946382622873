import { Box, ThemeProvider, Button } from '@mui/material';
import aldThemeOscuro from './themeConfig';


function DownloadPage() {
    return (
        <ThemeProvider theme={aldThemeOscuro}>
            <Box>
                <Button variant="contained" href="http://dev.echoesofneftania.com/Installer/NeftaniaInstaller.exe" >Download latest version</Button>
            </Box>


        </ThemeProvider>
    );
}

export default DownloadPage;