import React from "react";
import { Box, ImageList, ImageListItem, ThemeProvider } from "@mui/material";
import aldThemeOscuro from './themeConfig';

import LogInPage from './LogInPage';

function getToken() {
    const tokenString = sessionStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken;
}

const cardList = [
    {
        fullCardImg: 'http://dev.echoesofneftania.com/fullCardImgs/0.jpg',
        cardId: '0',
    },
    {
        fullCardImg: 'http://dev.echoesofneftania.com/fullCardImgs/1.jpg',
        cardId: '1',
    },
    {
        fullCardImg: 'http://dev.echoesofneftania.com/fullCardImgs/2.jpg',
        cardId: '2',
    },
    {
        fullCardImg: 'http://dev.echoesofneftania.com/fullCardImgs/3.jpg',
        cardId: '3',
    },
    {
        fullCardImg: 'http://dev.echoesofneftania.com/fullCardImgs/4.jpg',
        cardId: '4',
    },
    {
        fullCardImg: 'http://dev.echoesofneftania.com/fullCardImgs/5.jpg',
        cardId: '5',
    },
    {
        fullCardImg: 'http://dev.echoesofneftania.com/fullCardImgs/6.jpg',
        cardId: '6',
    },
    {
        fullCardImg: 'http://dev.echoesofneftania.com/fullCardImgs/7.jpg',
        cardId: '7',
    },
    {
        fullCardImg: 'http://dev.echoesofneftania.com/fullCardImgs/8.jpg',
        cardId: '8',
    },
    {
        fullCardImg: 'http://dev.echoesofneftania.com/fullCardImgs/9.jpg',
        cardId: '9',
    },
    {
        fullCardImg: 'http://dev.echoesofneftania.com/fullCardImgs/10.jpg',
        cardId: '10',
    },
    {
        fullCardImg: 'http://dev.echoesofneftania.com/fullCardImgs/11.jpg',
        cardId: '11',
    }
];
/*
function Card(fullCardImg, cardId) {
    return (
        <ImageListItem key={cardId}>
            <img 
                src={fullCardImg} 
                alt={cardId}
            />
        </ImageListItem>

    )
}
*/

function MyDecksPage() {
    const token = getToken();

    if (!token) {
        return (
            <Box>
                hay que hace loggin para ver esto
                <LogInPage />
            </Box>
        )
    }

    return (
        <ThemeProvider theme={aldThemeOscuro}>
            <Box>
                pagina donde se ven los Desks...
                <ImageList cols="5">
                    {cardList.map((card) => (
                        //<Card fullCardImg={card.fullCardImg} cardId={card.cardId}/>
                        <ImageListItem key={card.cardId}>
                            <img
                                src={card.fullCardImg}
                                alt={card.cardId}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>
        </ThemeProvider>


    );

}


export default MyDecksPage;
