import React, { useRef } from "react";
import { Box, Button, TextField, ThemeProvider } from "@mui/material";
//import { PropTypes } from "prop-types";

import aldThemeOscuro from './themeConfig';

async function getConfigJson() {
    return fetch("../config.json").then(data => data.json());
}

async function logInUser(tokenUrl, credentials) {
    return fetch(tokenUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}


async function logInUserFake(tokenUrl, credentials) {
    if (credentials.email === "aaa") {
        return {
            "data": "token.ejempoEstopuedeserasí12343",
            "lstMessages": null
        };
    } else {
        return {
            "data": null,
            "lstMessages": {errorCode: 'EON-USER-001', errorDescription: 'ERROR_INVALID_CREDENTIALS', additionalInformation: null}
        };
    }

}


function setToken(userToken) {
    sessionStorage.setItem("token", JSON.stringify(userToken));
}

function LogInPage() {

    const userMailRef = useRef();
    const userPasswordRef = useRef();

    async function onSubmit(e) {
        e.preventDefault();

        const fetchBody = {
            "email": userMailRef.current.value,
            "password": userPasswordRef.current.value
        };

        const configJson = await getConfigJson();

        const tokenUrl = configJson.TOKEN_URL;

        const wsResponse = await logInUser(tokenUrl, fetchBody);
        if (wsResponse.data === null) {
            alert("Incorrect user or password");


        } else {
            setToken(wsResponse.data);
            window.location.href="/";
        }
    }


    return (
        <ThemeProvider theme={aldThemeOscuro}>
            <Box>
                <form onSubmit={onSubmit}>
                    <TextField
                        required
                        type="text"
                        inputRef={userMailRef}
                        label="userMail"
                        id="userMail"
                        variant="outlined"
                    />
                    <TextField
                        type="password"
                        inputRef={userPasswordRef}
                        label="userPassword"
                        name="userPassword"
                        variant="outlined"
                    />
                    <br></br>
                    <Button variant="contained" type="submit">Accept</Button>
                </form>
            </Box>
        </ThemeProvider>


    );

}
/*
LogInPage.propTypes = {
    setToken: PropTypes.func.isRequired
}
*/
export default LogInPage;



