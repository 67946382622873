import React, { useRef } from "react";
import { Box, Button, TextField, ThemeProvider } from "@mui/material";
import aldThemeOscuro from './themeConfig';

async function getConfigJson() {
    return fetch("../config.json").then(data => data.json());
}

async function signInUser(fetchUrl, credentials) {
    return fetch(fetchUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}


function SignInPage(props) {

    const userMailRef = useRef();
    const userNameRef = useRef();
    const userPasswordRef = useRef();


    async function onSubmit(e) {
        e.preventDefault();
        //alert("userMail: " + userMailRef.current.value + ", userName: " + userNameRef.current.value + ", userPassword: " + userPasswordRef.current.value);


        let fetchBody = {
            "password": userPasswordRef.current.value,
            "email": userMailRef.current.value,
            "alias": userNameRef.current.value
        };

        const configJson = await getConfigJson();

        const wsResponse = await signInUser(configJson.CREATE_URL, fetchBody);
        //alert(JSON.stringify(wsResponse));
        if (wsResponse.lstMessages === null) {
            alert("Sign in OK. You will receive an email to confirm");
            window.location.href="/";
        } else {
            alert("Error! " + JSON.stringify(wsResponse.lstMessages));
        }

    }


    return (
        <ThemeProvider theme={aldThemeOscuro}>
            <Box>   
                <form onSubmit={onSubmit}>
                    <TextField
                        required
                        type="text"
                        inputRef={userMailRef}
                        label="userMail"
                        id="userMail"
                        variant="outlined"
                    />
                    <TextField

                        type="text"
                        inputRef={userNameRef}
                        label="userName"
                        name="userName"
                        variant="outlined"
                    />
                    <TextField

                        type="password"
                        inputRef={userPasswordRef}
                        label="userPassword"
                        name="userPassword"
                        variant="outlined"
                    />
                    <br></br>
                    <Button variant="contained" type="submit">Accept</Button>
                </form>
            </Box>
        </ThemeProvider>


    );

}

export default SignInPage;
