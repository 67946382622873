import {createTheme} from '@mui/material/styles';

import { purple, grey } from '@mui/material/colors';

const aldThemeOscuro = createTheme({
    palette: {
        primary: {
            main: grey[600]
        },
        secondary: {
            main: purple[600]
        }
    }

});

export default aldThemeOscuro;