import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles';

import aldThemeOscuro from './themeConfig';
import AppLoggedPage from './AppLoggedPage';
import AppNoLoggedPage from './AppNoLoggedPage';
import HomePage from './HomPage';
import MyDecksPage from './MyDecksPage';
import NotFoundPage from './NotFoundPage';
import LogInPage from './LogInPage';
import SignInOkPage from './SignInOkPage';
import SignInConfirmPage from './SignInConfirmPage';
import DownloadPage from './DownloadPage';
import SignInPage from './SignInPage';


function getToken() {
  const tokenString = sessionStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  return userToken;
}

function AppPage() {
  const navigate = useNavigate();
  const token = getToken();

  let currentApp;
  if (token) {
    currentApp = <AppLoggedPage navigateMain={navigate}/>;
  } else {
    currentApp = <AppNoLoggedPage/>;
  }

  return (
    <ThemeProvider theme={aldThemeOscuro}>
        <Routes>
          <Route path="/" element={currentApp}>
            <Route index element={<HomePage/>} />
            <Route path="/MyDecks" exact element={<MyDecksPage/>} />
            <Route path="/Download" exact element={<DownloadPage/>} />
            <Route path="/LogIn" exact element={<LogInPage/>} />
            <Route path="/SignIn" exact element={<SignInPage/>} />
            <Route path="/SignInOk" exact element={<SignInOkPage/>} />
          </Route>
          <Route path="/SignInConfirm" exact element={<SignInConfirmPage/>} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
    </ThemeProvider>

  )
}
export default AppPage;

