import { Box, ThemeProvider } from '@mui/material';
import aldThemeOscuro from './themeConfig';


function NotFoundPage() {
    return(
        <ThemeProvider theme={aldThemeOscuro}>
            
            <Box>Not found!!</Box>
        </ThemeProvider>
    );
}

export default NotFoundPage;

