import React from "react";
import { Box, ThemeProvider } from "@mui/material";
import aldThemeOscuro from './themeConfig';






function SignInOkPage(props) {
    return(
        <ThemeProvider theme={aldThemeOscuro}>
            <Box>
                Sign in OK. You will receive a mail to validate and bla bla bla
            </Box>
        </ThemeProvider>


    );

}

export default SignInOkPage;