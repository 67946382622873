import React from "react";
import { Box, Button, ThemeProvider } from "@mui/material";
import aldThemeOscuro from './themeConfig';
import { useSearchParams } from "react-router-dom";


const fetchUrl = "http://neftania-webapi.duckdns.org/api/user/validate";

async function confirmUser(credentials) {
    return fetch(fetchUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

/*
async function confirmUserFake(credentials) {
    alert(JSON.stringify(credentials));
    return (
        {
            "error": "NO"
        }
    );
}
*/






function SignInConfirmPage() {
    const[searchParams] = useSearchParams();
    const inToken = searchParams.get("token");
    const inEmail = searchParams.get("email");


    async function onSubmit(e) {
        e.preventDefault();


        const fetchBody = {
            "email": inEmail,
            "token": inToken
        };

        const wsResponse = await confirmUser(fetchBody);

        if (wsResponse.error === "NO") {
            alert("sign in OK");
        } else {
            alert("sign in KO");
        }

    }


    return (
        <ThemeProvider theme={aldThemeOscuro}>
            <Box>
                botón de confirmar...
                <form onSubmit={onSubmit}>
                    <Button variant="contained" type="submit">Accept</Button>
                </form>

            </Box>
        </ThemeProvider>


    );

}

export default SignInConfirmPage;


