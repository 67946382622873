import { Box, ThemeProvider } from '@mui/material';
import aldThemeOscuro from './themeConfig';


function HomePage() {
    return(
        <ThemeProvider theme={aldThemeOscuro}>
            <Box
              component="img"
              src="./Cabecera-full-hd-1536x1041.png"
              style={{ width: "80%", height: "80%" }}
            />
        </ThemeProvider>
    );
}

export default HomePage;

